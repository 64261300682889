import clsx from "clsx";
import React from "react";
import { Paragraph } from "../ui/typography";
import { Icon } from "../ui/icons";
import type { NavLinkGroup, Link } from "~/types/sanity-schema";
import { SubNavLinkGroup } from "~/components/meganav/subnav-link-group";
import { asText, asLink } from "~/utils/sanity-helpers";
import { Link as LinkComponent } from "~/components/ui/link";
import { useLocation } from "@remix-run/react";

type ButtonProps = JSX.IntrinsicElements["button"];
interface SubNavButtonProps extends ButtonProps {
	buttonName: string;
	onClick: () => void;
	active: boolean;
	variant: "primary" | "secondary";
}

function SubNavButton(props: SubNavButtonProps) {
	const { buttonName, onClick, id, variant, active } = props;

	const variants = {
		primary: "hover:bg-secondary justify-between pl-6 pr-7 py-5",
		secondary:
			"underline-offset-8 gap-3 hover:underline decoration-theme-primary px-6 py-5",
	};

	const activeClasses = {
		primary: "text-primary",
		secondary: "text-primary",
	};

	function renderButtonIcon() {
		if (variant === "primary") {
			return <Icon name="chevron-right" height="8px" width="10px" />;
		}

		return <Icon name="chevron-down" height="8px" width="10px" />;
	}

	return (
		<button
			id={`mobile-${id}`}
			aria-expanded={props["aria-expanded"]}
			onClick={onClick}
			className={clsx(
				"flex w-full items-center",
				variants[variant],
				active && activeClasses[variant]
			)}
		>
			<Paragraph as="span" size="button" color="current">
				{buttonName}
			</Paragraph>
			{renderButtonIcon()}
		</button>
	);
}

function BackButton(props: Omit<SubNavButtonProps, "active">) {
	const { buttonName, onClick } = props;

	return (
		<button
			onClick={onClick}
			className={clsx(
				"border-stroke hover:bg-secondary flex w-full items-center gap-4 border-b px-7 py-6"
			)}
		>
			<Icon name="arrow-left" />
			<Paragraph as="span" size="button" color="current">
				{buttonName}
			</Paragraph>
		</button>
	);
}

interface Props {
	buttonName: string;
	active?: boolean;
	navLinkGroups?: Array<NavLinkGroup>;
	navSubmenuItems?: Array<Link>;
	id: string;
	onClick: (id: string) => void;
}

export function NavSection({
	buttonName,
	active,
	navLinkGroups,
	id,
	onClick,
}: Props) {
	const navSectionContentRef = React.useRef<HTMLDivElement>(null);
	const location = useLocation();

	React.useEffect(() => {
		if (navSectionContentRef.current && active) {
			navSectionContentRef.current.scrollTop = 0;
		}
	}, [active]);

	if (!navLinkGroups) {
		return null;
	}

	const subNavActive = navLinkGroups
		.flatMap((group) => group.items)
		.some((item) => {
			const to = asLink(item?.navLink);

			if (!to) return false;

			return to === location.pathname || location.pathname.startsWith(`${to}/`);
		});

	return (
		<div>
			<SubNavButton
				active={subNavActive}
				id={id}
				aria-expanded={active}
				onClick={() => onClick(id)}
				buttonName={buttonName}
				variant="primary"
			/>
			<div
				role="region"
				aria-labelledby={`mobile-${id}`}
				aria-hidden={!active}
				ref={navSectionContentRef}
				className={clsx(
					"fixed bottom-0 left-0 right-0 top-mobile-nav z-20 overflow-y-scroll bg-[white] transition-all duration-300 ease-out",
					{ "translate-x-0": active },
					{ "invisible translate-x-full": !active }
				)}
			>
				<div className="sticky top-0 bg-[white]">
					<BackButton
						variant="primary"
						id={id}
						onClick={() => onClick(id)}
						buttonName={buttonName}
					/>
				</div>

				<div className="flex flex-col gap-6 px-8 py-6">
					{navLinkGroups.map((group, index) => {
						return group.items ? (
							<SubNavLinkGroup
								key={index}
								headerText={asText(group.title)}
								items={group.items}
							/>
						) : null;
					})}
				</div>
			</div>
		</div>
	);
}

export function SecondaryNavSection({
	buttonName,
	active,
	navSubmenuItems,
	id,
	onClick,
}: Props) {
	const location = useLocation();

	if (!navSubmenuItems) {
		return null;
	}

	const isSubNavActive = navSubmenuItems.some((item) => {
		const url = asLink(item);

		if (!url) return false;

		return url === location.pathname || location.pathname.startsWith(`${url}/`);
	});

	return (
		<div>
			<SubNavButton
				active={isSubNavActive}
				id={id}
				aria-expanded={active}
				onClick={() => onClick(id)}
				buttonName={buttonName}
				variant="secondary"
			/>
			<div
				role="region"
				aria-labelledby={id}
				aria-hidden={!active}
				className={clsx(
					"px-6",
					{ "flex flex-col": active },
					{ hidden: !active }
				)}
			>
				{navSubmenuItems.map((item, index) => {
					const url = asLink(item);

					return url ? (
						<LinkComponent
							key={index}
							to={url}
							trackingText={item.title}
							trackingPosition="menu"
							className="block w-full gap-2 py-3 pl-6 text-left decoration-theme-primary underline-offset-8 hover:underline"
						>
							<Paragraph as="span" size="button" fontWeight="font-normal">
								{item.title}
							</Paragraph>
						</LinkComponent>
					) : null;
				})}
			</div>
		</div>
	);
}
